export const finicalCarouselItem = [
    {
        key: 'cw_1',
        title: '票据远传, 不用纸票',
        imgUrl: '/companyindex/pjyc.jpg',
        sortUrl: '/companyindex/01.png'
    },
    {
        key: 'cw_2',
        title: '智能制单, 省时省力',
        imgUrl: '/companyindex/znzd.jpg',
        sortUrl: '/companyindex/02.png'
    },
    {
        key: 'cw_3',
        title: '票证一体, 查找方便',
        imgUrl: '/companyindex/pzyt.jpg',
        sortUrl: '/companyindex/03.png'
    },
    {
        key: 'cw_4',
        title: '财务报表, 自动生成',
        imgUrl: '/companyindex/zdcb.jpg',
        sortUrl: '/companyindex/04.png'
    },
    {
        key: 'cw_5',
        title: '绑定手机, 责任清晰',
        imgUrl: '/companyindex/bdsj.jpg',
        sortUrl: '/companyindex/05.png'
    },
    {
        key: 'cw_6',
        title: '穿透查询, 掌控财务',
        imgUrl: '/companyindex/ctcx.jpg',
        sortUrl: '/companyindex/06.png'
    },
]

export const reimbursementCarouselItem = [
    {
        key: 'bx_1',
        title: '发票查验, 合规省费',
        imgUrl: '/companyindex/fpyz.jpg',
        sortUrl: '/companyindex/01.png'
    },
    {
        key: 'bx_2',
        title: '重复报销, 从此消除',
        imgUrl: '/companyindex/cfbx.jpg',
        sortUrl: '/companyindex/02.png'
    },
    {
        key: 'bx_3',
        title: '无票报销, 样样周到',
        imgUrl: '/companyindex/wpbx.jpg',
        sortUrl: '/companyindex/03.png'
    },
    {
        key: 'bx_4',
        title: '精打细算, 节约税款',
        imgUrl: '/companyindex/jdxs.jpg',
        sortUrl: '/companyindex/04.png'
    },
    {
        key: 'bx_5',
        title: '智能审核, 效率翻番',
        imgUrl: '/companyindex/znsh.jpg',
        sortUrl: '/companyindex/05.png'
    }
]

export const accountAgencyCarouselItem = [
    {
        key: 'ag_1',
        title: '交易质量, 双重保障',
        imgUrl: '/companyindex/jyzl.jpg',
        sortUrl: '/companyindex/01.png'
    },
    {
        key: 'ag_2',
        title: '双向选择, 自主更换',
        imgUrl: '/companyindex/sxxz.jpg',
        sortUrl: '/companyindex/02.png'
    },
    {
        key: 'ag_3',
        title: '双方权益, 平台保障',
        imgUrl: '/companyindex/sfqy.jpg',
        sortUrl: '/companyindex/03.png'
    },
    {
        key: 'ag_4',
        title: '会计工作, 轻松自由',
        imgUrl: '/companyindex/kjgz.jpg',
        sortUrl: '/companyindex/04.png'
    }
]

export const companyInfo = [
    {
        title: '2023年',
        desc: '公司自研产品开始推广, 截至目前本地化部署单位扩展至4家, 云企业用户50+'
    },
    {
        title: '2021年',
        desc: '公司顺应国家发展数字经济之趋势, 转型到自主研发新一代数字管理软件, 帮助企业实现经营管理数字化'
    },
    {
        title: '2018年',
        desc: '用友软件平顶山分公司注销, 平顶山心友公司业务转入到河南用心网络科技有限公司'
    },
    {
        title: '2010年',
        desc: '原平顶山用友软件有限公司拆分为用友软件股份有限公司平顶山分公司和平顶山心友软件有限公司, 两公司办公地址迁至平顶山市中兴路华诚大厦九楼, 郭万魁出任用友平顶山分公司总经理'
    },
    {
        title: '2005年',
        desc: '签约平煤集团集中财务系统, 一次上线256家单位, 获得“煤炭采掘业信息化示范项目”, 成为煤炭行业和用友体系双第一'
    },
    {
        title: '2003年',
        desc: '签约平顶山第一家高端ERP用户天宏焦化。系统覆盖财务、供应、销售、人力、生产等关键业务'
    },
    {
        title: '1999年',
        desc: '公司成立, 与北京用友集团合资成立了中西部省区唯一一家地区级子公司——平顶山用友软件有限公司，地址位于平顶山市湛河南路工业学校东二楼'
    }
]

export const newsInfo = [
    {
        title: '2024年',
        desc: '全数票在全国范围内全面推行'
    },
    {
        title: '2023年4月',
        desc: '国家档案局、财政部、商务部和国家税务总局四部门联合发布《电子发票全流程电子化管理指南》'
    },
    {
        title: '2023年2月6日',
        desc: '国家税务总局、财政部、商务部和国家档案局联合发文，将全电票试点工作从原来的上海、广东和内蒙古扩展16个省（含若干副省级城市），河南列入其中'
    },
    {
        title: '2022年1月24日',
        desc: '国务院国发〔2021〕29号印发《“十四五”数字经济发展规划》，发展数字经济上升为国家战略'
    },
    {
        title: '2020年9月28日',
        desc: '税务总局等十三部门发布《关于推进纳税缴费便利化改革优化税收营商环境若干措施的通知》税总发〔2020〕48号，明确分两批对全国新办纳税人的增值税专票实行电子化，河南省作为第二批于2021年1月21日执行'
    },
    {
        title: '2020年3月31日',
        desc: '财政部和国家档案局联合发文财会[2020]6号, 推行电子票据入账归档, 为财务档案无纸化提供了法律依据'
    }
]

export const imgList = [
    '/companyindex/cert_bx.png',
    '/companyindex/cert_cw.png',
    '/companyindex/cert_cg.png',
    '/companyindex/cert_ch.png'
]

export const serviceOptions = [
    {
        id: '1745341398167932930',
        type: 'daizhang_invoiced',
        name: '代开发票'
    },
    {
        id: '1745341815123693570',
        type: 'accoun_issuetable',
        name: '记账出表'
    },
    {
        id: '1745341815136276481',
        type: 'tax_declaration',
        name: '纳税申报'
    },
    {
        id: '1745341815144665090',
        type: 'income_taxsettlement',
        name: '所得税汇算'
    },
    {
        id: '1745342110159425537',
        type: 'business_annualreport',
        name: '工商年报'
    }
]

export const plainOptions = [
    {
        label: '正常申报',
        value: 'normalDeclare'
    },
    {
        label: '零申报(有费用)',
        value: 'zeroDeclare'
    },
    {
        label: '零申报(无费用)',
        value: 'zeroDeclareNoCost'
    }
]

export const certOptions = [
    {
        label: '高级会计师',
        value: 'A-seniorAccountant'
    },
    {
        label: '中级会计师',
        value: 'B-intermediateAccountant'
    },
    {
        label: '初级会计师',
        value: 'C-juniorAccountant'
    }
]

export const eduOptions = [
    {
        label: '博士',
        value: 'A-doctor'
    },
    {
        label: '硕士',
        value: 'B-master'
    },
    {
        label: '本科',
        value: 'C-undergraduate'
    },
    {
        label: '专科',
        value: 'D-training'
    },
    {
        label: '高中',
        value: 'E-senior'
    },
    {
        label: '中专',
        value: 'E-polytechnic'
    },
    {
        label: '初中',
        value: 'F-middle'
    }
]

export const yearOptions = [
    {
        label: '1~2年',
        value: '1'
    },
    {
        label: '3~4年',
        value: '2'
    },
    {
        label: '5~10年',
        value: '3'
    },
    {
        label: '10年以上',
        value: '4'
    }
]

export const tableColumn = [
    {
        title: '头像',
        dataIndex: 'avatar',
        width: 100,
    },
    {
        title: '会计信息',
        align: 'left',
        dataIndex: 'userName'
    },
    {
        title: '价格',
        width: 180,
        align: 'left',
        dataIndex: 'accountingPrice',
        helpMessage: ['价格计算中的地区系数：以代理人工作地为准'],
    },
    {
        title: 'Action',
        dataIndex: 'action',
    }
]

export const Columns = [
    {
        title: '企业名称',
        align: 'left',
        dataIndex: 'creatorCompanyName',
        slots: { customRender: 'creatorCompanyName' },
        width: 1000,
    },
    {
        title: '申报方式',
        align: 'left',
        dataIndex: 'declareType_dictText',
        slots: { customRender: 'declareType_dictText' },
        fixed: 'right',
        width: 300,
    }
];

export const imageList = [
    '/companyindex/wzkj1.jpg',
    '/companyindex/wzkj2.jpg',
    '/companyindex/wzkj3.jpg',
    '/companyindex/wzkj4.jpg',
    '/companyindex/wzkj5.jpg'
]

export const companyImgList = [
    '/companyindex/wzqy1.jpg',
    '/companyindex/wzqy2.jpg',
    '/companyindex/wzqy3.jpg',
]

export const footerImgList = [
    {
        label: '客服微信',
        img: '/companyindex/kfql.png'
    },
    {
        label: '微信公众号',
        img: '/companyindex/gzh.jpg'
    },
    {
        label: '微信小程序',
        img: '/companyindex/xcx.jpg'
    }
]